<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #aftercmsleftcomponents>
      <div class="p-2">
        <b-button type="is-primary" class="w-full" @click="click">Back</b-button>
      </div>
    </template>
  </page>
</template>

<script>
import CMSPage from './base/CMSPage'
export default {
  extends: CMSPage,
  methods: {
    click () {
       this.$router.replace('/user/orders')
    }
  },
  computed: {
    id () {
      return this.$route.query.Oid
    }
  }
}
</script>

<style>

</style>
